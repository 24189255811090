import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * @jsdoc Service
 *
 * @description
 * This service contain current width of user window. Please implement HostListener
 *
 * @example
 * @HostListener('window:resize', ['$event'])
 * public onResize(event: any): void {
 *   this.breakpointService.pageWidth.next(event.target.innerWidth);
 * }
 */
@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  public pageWidth: BehaviorSubject<number> = new BehaviorSubject(null);
  public pageWidth$: Observable<number> = this.pageWidth.asObservable();
}
